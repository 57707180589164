jQuery(document).ready(function ($) {


    $('.open-popup-link').magnificPopup({
        type: 'inline',
        midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });

    var currentVraag = '',
        nextVraag = '',
        aandacht = '',
        vraagArray = [],
        historyArray = [],
        antwoorden = [];

    function saveCurrentAntwoord(element, vraag, update, index) {
        var _ = $(this),
            step = element.closest('.js-step'),
            nextvraag = step.data('nextvraag'),
            input = step.find('input:checked'),
            hasAandacht = false,
            aandacht = step.find('.js-aandacht'),
            antwoordcontainer = step.find('.js-antwoord'),
            welkeaandacht = '';

        if (!input.length) {

            input = step.find('textarea').val();
            welkeaandacht = step.find('.js-vraag').data('aandacht');

            antwoordObj = {};
            antwoordObj.antwoord = input;
            antwoordObj.welkeaandacht = welkeaandacht;

            antwoorden.push(antwoordObj);

            if (welkeaandacht) {
                hasAandacht = true;
            }
        } else {
            $.each($(input), function () {
                var __ = $(this),
                    welkeaandacht = __.closest('.js-vraag').data('aandacht'),
                    antwoordObj = {};

                antwoordObj.antwoord = __.val();
                antwoordObj.input = __.closest('.js-vraag').find('textarea').val();
                antwoordObj.welkeaandacht = welkeaandacht;

                antwoorden.push(antwoordObj);

                if (welkeaandacht) {
                    hasAandacht = true;
                }
            });
        }


        let obj = {};


        // var inputField = antwoorden[0].input,
        //     antwoordField = antwoorden[0].antwoord;

        // console.log(inputField);
        // console.log(antwoordField);

        console.log(antwoorden);

        var doupdateonce = false;

        if (update) {
            doupdateonce = true;

            $.each(antwoorden, function (index, value) {
                var inputField = antwoorden[index].input,
                    antwoordField = antwoorden[index].antwoord;

                if(doupdateonce){
                    antwoordcontainer.empty();
                }

                if (inputField === undefined) {
                    antwoordcontainer.append(antwoordField.toString() + '<br/>');
                    doupdateonce = false;
                } else {
                    antwoordcontainer.append(antwoordField.toString() + ' ' + inputField.toString() + '<br/>');
                    doupdateonce = false;
                }
            });

            console.log('do update');
            vraagArray[index].vraag = vraag;
            vraagArray[index].antwoord = antwoorden;

            if (hasAandacht) {
                vraagArray[index].aandacht = aandacht.html();
            } else {
                delete vraagArray[index].aandacht;
            }
        } else {
            $.each(antwoorden, function (index, value) {
                var inputField = antwoorden[index].input,
                    antwoordField = antwoorden[index].antwoord;

                if (inputField === undefined) {
                    antwoordcontainer.append(antwoordField.toString() + '<br/>');
                } else {
                    antwoordcontainer.append(antwoordField.toString() + ' ' + inputField.toString() + '<br/>');
                }
            });

            $.each(vraagArray, function (index, value) {
                if (value.vraag === vraag) {
                    vraagArray.splice(index, 1);
                }
            });

            obj.vraag = vraag;
            obj.antwoord = antwoorden;

            if (hasAandacht) {
                obj.aandacht = aandacht.html();
            }

            vraagArray.push(obj);
            historyArray.push(obj);
        }

        antwoorden = [];

        // console.log(vraagArray);
    }

    function showResults() {
        var container = $('.js-active-step');

        container.empty();

        $.each(vraagArray, function (index, value) {
            var cf = $('.js-custom-field textarea'),
                oldVal = cf.val(),
                newVal = value.aandacht,
                combinedVal = oldVal + ' ' + newVal;

            cf.val($.trim(combinedVal));


            $(value.aandacht).appendTo('.js-active-step');
            // $(value.aandacht).appendTo('.js-custom-field');
        });

        $('.fwQuiz').addClass('__results');
        $('body').addClass('showresults');

        $('html, body').animate({scrollTop: 0}, "slow");
    }

    function showTerminate(step) {
        var container = $('.js-active-step'),
            terminateText = step.find('.js-terminate').html();

        container.empty();

        container.append(terminateText);

        $('.fwQuiz').addClass('__terminate');
        $('body').addClass('showterminate');

        $('html, body').animate({scrollTop: 0}, "slow");


    }

    function hideNext(index) {
        var container = $('.js-active-step'),
            childs = container.find('.js-step'),
            countChilds = childs.length,
            i = index + 1;

        while (i < countChilds) {
            childs.eq(i).addClass('__hidden');
            i++;
        }
    }

    function loadNextQuestion(vraag, step) {
        var _ = $(this);

        if (vraag === 0) {
            showResults();
        } else if (vraag === -1) {
            showTerminate(step);
        } else {
            $.ajax({
                url: "/wp-admin/admin-ajax.php?action=get_vraag",
                data: {
                    giveMeThisQuestion: vraag,
                },
                type: 'POST',
                success:
                    function (result) {
                        var data = JSON.parse(result),
                            step = $('.js-step');
                        step.addClass('__inactive');
                        step.removeClass('__active');
                        $(data).appendTo('.js-active-step-container');

                        var elementpos = $('.js-active-step-container .js-step').last().position().top,
                            menuheight = $('.fwMenu').height(),
                            scrollpos = elementpos - menuheight;

                        $('body, html').animate({
                            scrollTop: scrollpos
                        }, 765)
                    }
            })
        }
    }

    function showNextQuestion(vraag) {
        $('.js-step[data-vraag=' + vraag + ']').removeClass('__hidden');
        $('.js-step').addClass('__inactive');
        $('.js-step').removeClass('__active');
        $('.js-step[data-vraag=' + vraag + ']').removeClass('__inactive');
        $('.js-step[data-vraag=' + vraag + ']').addClass('__active');
    }

    $(document).on('click', '.js-next', function (e) {
        var _ = $(this),
            step = _.closest('.js-step'),
            currentVraag = step.data('vraag'),
            checkedinputs = step.find('input:checked'),
            checkedTextarea = step.find('textarea').val(),
            nextVraag = step.find('input:checked').closest('.js-vraag').data('gotovraag'),
            nextVraag2 = step.find('textarea').closest('.js-vraag').data('gotovraag'),
            presentos = false,
            presentosIndex = '';

        e.preventDefault();

        if (checkedinputs.length !== 0 || checkedTextarea) {

            var inputfield = '',
                inputfieldvalue = '';

            if (checkedTextarea) {
                inputfield = step.find('textarea');
                inputfieldvalue = checkedTextarea;
                nextVraag = nextVraag2;

            } else {
                inputfield = checkedinputs.closest('.js-vraag').find('textarea');
                inputfieldvalue = inputfield.val();
            }

            if (inputfield.length === 1) {
                if (inputfieldvalue) {
                    if (jQuery(".js-step[data-vraag=" + nextVraag + "]").length) {
                        presentos = true;
                        presentosIndex = $('.js-step[data-vraag=' + nextVraag + ']').index() - 1;
                    }

                    if (presentos) {
                        saveCurrentAntwoord(_, currentVraag, true, presentosIndex);
                        showNextQuestion(nextVraag);
                    } else {
                        saveCurrentAntwoord(_, currentVraag, false);
                        loadNextQuestion(nextVraag, step);
                    }
                } else {
                    step.addClass('__error');
                }
            } else {
                if (jQuery(".js-step[data-vraag=" + nextVraag + "]").length) {
                    presentos = true;
                    presentosIndex = $('.js-step[data-vraag=' + nextVraag + ']').index() - 1;
                }

                if (presentos) {
                    saveCurrentAntwoord(_, currentVraag, true, presentosIndex);
                    showNextQuestion(nextVraag);
                } else {
                    saveCurrentAntwoord(_, currentVraag, false);
                    loadNextQuestion(nextVraag, step);
                }
            }


        } else {
            step.addClass('__error');
        }
    });

    $(document).on('change', 'input[type=radio], input[type=checkbox]', function (e) {
        var _ = $(this),
            step = _.closest('.js-step'),
            welkeaandacht = _.closest('.js-vraag').data('aandacht'),
            naarvraag = _.closest('.js-vraag').data('gotovraag');

        step.find('.aandacht').attr('data-aandacht', welkeaandacht);
        step.attr('data-nextvraag', naarvraag);

        step.removeClass('__error');
    });

    // HIER KOMT TEXTAREA FOCUS REMOVE ERROR

    $(document).on('focus', 'textarea', function (e) {
        var _ = $(this),
            step = _.closest('.js-step');

        step.removeClass('__error');
    });

    $(document).on('change', 'input[type=checkbox]', function (e) {
        var _ = $(this),
            vraag = _.closest('.js-vraag');

        vraag.toggleClass('__toggled');
    });

    $(document).on('change', 'input[type=radio]', function (e) {
        var _ = $(this),
            vraag = _.closest('.js-vraag'),
            allvraag = _.closest('.step__antwoorden').find('.js-vraag');

        allvraag.removeClass('__toggled');
        vraag.addClass('__toggled');
    });

    $(document).on('click', '.__inactive', function (e) {
        var _ = $(this),
            allStep = $('.js-step'),
            index = _.index('.js-step');

        e.preventDefault();

        if (!_.hasClass('__active')) {
            allStep.removeClass('__active');
            _.addClass('__active');
            _.removeClass('__inactive');

            hideNext(index);
        }
    });

    $(document).on('click', '.js-open', function (e) {
        var _ = $(this),
            step = _.closest('.js-step');

        step.addClass('__open');
    });

    $(document).on('click', '.js-close', function (e) {
        var _ = $(this),
            step = _.closest('.js-step');

        step.removeClass('__open');
    });

    $('.js-print').on('click', function (e) {
        e.preventDefault();
        downloadPDF();
    });

    $('.js-download').on('click', function (e) {
        e.preventDefault();
        window.print();
    });

    $('.js-restartbutton').on('click', function (e) {
        e.preventDefault();
        location.reload();
    })
});